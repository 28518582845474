import Feature from 'ol/Feature.js';
import Map from 'ol/Map.js';
import Point from 'ol/geom/Point.js';
import Select from 'ol/interaction/Select.js';
//import Stamen from 'ol/source/Stamen.js';
import VectorSource from 'ol/source/Vector.js';
import MousePosition from 'ol/control/MousePosition.js';
import { createStringXY } from 'ol/coordinate.js';
import View from 'ol/View.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import { Circle } from 'ol/geom.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { fromLonLat } from 'ol/proj.js';
import { CartoDB, OSM } from 'ol/source.js';
import Overlay from 'ol/Overlay.js';

import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4/dist/proj4.js';
import geojsonSettori from './data/geojson/settori.json';
import geojsonBlocchi from './data/geojson/blocchi.json';
import geojsonRutinoblocchi from './data/geojson/rutinoblocchi.json';
import { register } from 'ol/proj/proj4.js';
import { get as getProjection, getTransform } from 'ol/proj.js';
import { applyTransform } from 'ol/extent.js';
import { ScaleLine, defaults as defaultControls } from 'ol/control.js';
import { getPointResolution, transform, } from 'ol/proj.js';




const projection = getProjection('EPSG:4326');


/**
 * Definisce lo stile del cerchio 
 */
function GetCircleStyle() {
    var style = new Style({
        renderer(coordinates, state) {
            const [[x, y], [x1, y1]] = coordinates;
            const ctx = state.context;
            const dx = x1 - x;
            const dy = y1 - y;
            const radius = Math.sqrt(dx * dx + dy * dy);
            const innerRadius = 0;
            const outerRadius = radius * 1.4;
            const gradient = ctx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
            gradient.addColorStop(0, 'rgba(255,0,0,0)');
            gradient.addColorStop(0.6, 'rgba(255,0,0,0.2)');
            gradient.addColorStop(1, 'rgba(255,0,0,0.8)');
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
            ctx.fillStyle = gradient;
            ctx.fill();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
            ctx.strokeStyle = 'rgba(255,0,0,1)';
            ctx.stroke();
        },
    });
    return style;
}



/**
 * Disegna un'cerchio
 */
function DrawCircle(Id, CenterX, CenterY, Radius, CustomProp) {

    var c = transform([CenterX, CenterY], 'EPSG:4326', projection);
    let circleFeature = new Feature({
        geometry: new Circle(c, Radius),
    });
    circleFeature.setStyle(GetCircleStyle());
    circleFeature.setId(Id);
    circleFeature.setProperties = ({
        Nome: CustomProp
    });
    return circleFeature;
}


var a = 15.1283930000;
var b = 40.3454460000;

const myCircle = DrawCircle(1, a, b, 0.00001, "Pippo");


/**
 * Necessari per la gestione del popup.
 */
const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

/**
 * Creo un layer di sovrapposizione per ancorare il popup alla mappa
 */
const overlay = new Overlay({
    element: container,
    autoPan: {
        animation: {
            duration: 250,
        },
    },
});

/**
 * clik per chiudere il popup
 * @return {boolean} Restitusco false per evitare l'href.
 */
closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};



const mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(4),
    projection: 'EPSG:4326',
    // comment the following two lines to have the mouse position
    // be placed within the map.
    className: 'custom-mouse-position',
    target: document.getElementById('mouse-position'),
});




import geojresultCicerealeAllacci from './data/geojson2/jresulteAllacci.json';
import geojresultCicerealeReteAP from './data/geojson2/jresulteReteAP.json';
import geojresultCicerealeReteBP from './data/geojson2/jresulteReteBP.json';
import geojresultCicerealeDiramazioni from './data/geojson2/jresulteDiramazioni.json';
import geojresultCicerealeNodi from './data/geojson2/jresultNodi.json';

import geojresultCicerealePC from './data/geojson2/jresultPC.json';


import geojresultGiunganoMPi from './data/geojson2/jresultGiunganoMP.json';
import geojresultGiunganoRadiografia from './data/geojson2/jresultGiunganoRadiografia.json';
import geojresultGiunganoAllacci from './data/geojson2/jresultGiunganoAllacci.json';
import geojresultGiunganoAP from './data/geojson2/jresultGiunganoAP.json';
import geojresultGiunganoBP from './data/geojson2/jresultGiunganoBP.json';
import geojresultGiunganoDiramazioni from './data/geojson2/jresultGiunganoDiramazioni.json';
//import geojresultGiunganoNodi from './data/geojson2/jresultGiunganoNodi.json';



const stylesSettore = [

    new Style({
        filter: ['type'],
        stroke: new Stroke({
            color: 'blue',
            width: 3,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),


    }),
    new Style({
        image: new CircleStyle({
            radius: 3,
            fill: new Fill({
                color: 'red',
            }),
        }),


    }),
];
function GetVectorLayerNoTrasform(pathJson, nomeVectorLayer, zIndexView, colore) {
    return new VectorLayer({
        style: [
            new Style({
                filter: ['type'],
                stroke: new Stroke({
                    color: colore,
                    width: 3,
                }),
                fill: new Fill({
                    color: colore,
                }),
            }),
            new Style({
                image: new CircleStyle({
                    radius: 3,
                    fill: new Fill({
                        color: colore,
                    }),
                }),
            }),
        ],
        source: new VectorSource({
            features: new GeoJSON().readFeatures(pathJson),
        }),
        projection: 'EPSG:4326',
        Nome: nomeVectorLayer,
        zIndex: zIndexView,
    })
}



let LayerCiceraleReteBP = GetVectorLayerNoTrasform(geojresultCicerealeReteBP, "CicerealeReteBP", 1, 'blue');
let LayerCiceraleReteAP = GetVectorLayerNoTrasform(geojresultCicerealeReteAP, "CicerealeReteAP", 2, 'purple');
let LayerCiceraleAllacci = GetVectorLayerNoTrasform(geojresultCicerealeAllacci, "CicerealeAllacci", 3, 'red');
let LayerCicerealeDiramazioni = GetVectorLayerNoTrasform(geojresultCicerealeDiramazioni, "CicerealeDiramazioni", 4, 'green');
let LayerCicerealeNodi = GetVectorLayerNoTrasform(geojresultCicerealeNodi, "CicerealeNodi", 5, 'yellow');

let LayerjresultGiunganoMP = GetVectorLayerNoTrasform(geojresultGiunganoMPi, "GiunganoMP", 1, 'red');
let LayerjresultGiunganoRadiografia = GetVectorLayerNoTrasform(geojresultGiunganoRadiografia, "GiunganoRadiografia", 2, 'green');
let LayerjresultGiunganoAllacci = GetVectorLayerNoTrasform(geojresultGiunganoAllacci, "GiunganoAllacci", 3, 'blue');
let LayerjresultGiunganoAP = GetVectorLayerNoTrasform(geojresultGiunganoAP, "GiunganoAP", 1, 'purple');
let LayerjresultGiunganoBP = GetVectorLayerNoTrasform(geojresultGiunganoBP, "GiunganoBP", 4, 'yellow');
let LayerjresultGiunganoDiramazioni = GetVectorLayerNoTrasform(geojresultGiunganoDiramazioni, "GiunganoDiramazioni", 5, 'orange');
//let LayerjresultGiunganoNodi = GetVectorLayerNoTrasform(geojresultGiunganoNodi, "GiunganoNodi", 6, 'purple');




const scaleControl = new ScaleLine({
    units: 'metric',
    bar: true,
    steps: 4,
    text: true,
    minWidth: 140,
});


const centroMappa = [15.130257, 40.343340];


var miaview = new View({
    projection: projection,
    center: centroMappa,
    zoom: 13

});


/**
 * Gestione della mappa, "Attenzione ci stanno gli overlay cio� i livelli"
 * */
const map = new Map({
    controls: defaultControls().extend([scaleControl]),
    layers: [
        new TileLayer({
            source: new OSM(),

        }),
    ],
    overlays: [overlay],
    target: document.getElementById('map'),
    view: miaview,
});



const precisionInput = document.getElementById('precision');
precisionInput.addEventListener('change', function (event) {
    const format = createStringXY(event.target.valueAsNumber);
    mousePositionControl.setCoordinateFormat(format);
});


map.addLayer(LayerCiceraleReteBP);
map.addLayer(LayerCiceraleReteAP);
map.addLayer(LayerCiceraleAllacci);
map.addLayer(LayerCicerealeDiramazioni);
map.addLayer(LayerCicerealeNodi);
//map.addLayer(LayerCicerealePC);
//map.addLayer(LayerCicerealeCartografia);
//Gingano
map.addLayer(LayerjresultGiunganoMP);
map.addLayer(LayerjresultGiunganoRadiografia);
map.addLayer(LayerjresultGiunganoAllacci);
//map.addLayer(LayerjresultGiunganoAP);
map.addLayer(LayerjresultGiunganoBP);
map.addLayer(LayerjresultGiunganoDiramazioni);
map.addLayer(LayerjresultGiunganoAP);

var LayerCircle = new VectorLayer({
    source: new VectorSource({
        projection: 'EPSG:4326',
        features: [myCircle],
    }),
});


map.addLayer(LayerCircle);






function GetLayerByName(nome) {
    if (nome == "ReteBP") {
        return LayerCiceraleReteBP;
    } else if (nome == "ReteAP") {
        return LayerCiceraleReteAP;
    } else if (nome == "Allacci") {
        return LayerCiceraleAllacci;
    } else if (nome == "Diramazioni") {
        return LayerCicerealeDiramazioni;
    } else if (nome == "Nodi") {
        return LayerCicerealeNodi;
    } else if (nome == "GiunganoMP") {
        return LayerjresultGiunganoMP;
    } else if (nome == "GiunganoRadiografia") {
        return LayerjresultGiunganoRadiografia;
    } else if (nome == "GiunganoAllacci") {
        return LayerjresultGiunganoAllacci;
    } else if (nome == "GiunganoBP") {
        return LayerjresultGiunganoBP;
    } else if (nome == "GiunganoDiramazioni") {
        return LayerjresultGiunganoDiramazioni;
    }
    //  else if (nome == "GiunganoNodi") {
    //    return LayerjresultGiunganoNodi;
    //}
    else if (nome == "GiunganoAP") {
        return LayerjresultGiunganoAP;
    }



}

document.querySelector('#container').onclick = function (ev) {
    if (ev.target.id.toLowerCase() === 'ciceralecenter') {
     
        miaview.setCenter(centroMappa);
    }
    if (ev.target.checked) {
        map.addLayer(GetLayerByName(ev.target.value));
    } else {
        map.removeLayer(GetLayerByName(ev.target.value));
    }

}

document.querySelector('#containerGiungano').onclick = function (ev) {
   
    if (ev.target.id.toLowerCase() === 'giunganocenter') {
        const centrogiungano = [15.101412, 40.394674,];
        miaview.setCenter(centrogiungano);
    }
    if (ev.target.checked) {
        map.addLayer(GetLayerByName(ev.target.value));
    } else {
        map.removeLayer(GetLayerByName(ev.target.value));
    }

}





const select = new Select({
    //style: GetCircleStyleSelected(),

});

select.on('select', function (e) {
    var feature = e.selected[0];
    var feature = e.selected[0];
    if (feature) {
        var geom = feature.getGeometry();
        var coordinate = geom.getLastCoordinate();
        
        content.innerHTML = GetinnerHTMLGlobal(feature);
        
        overlay.setPosition(coordinate);
    }
});


function GetinnerHTMLGlobal(feature) {
    var result = '';
    var objeto = feature.getProperties();
    for (var propiedades in objeto) {
        if ((propiedades.toLowerCase() !== 'apgGiunganoid') && (propiedades.toLowerCase() !== 'bpgiunganoid') &&
            (propiedades.toLowerCase() !== 'allaccigiunganoid') && (propiedades.toLowerCase() !== 'adiografiegiunganoid') && (propiedades.toLowerCase() !== 'mpgiunganoid') && (propiedades.toLowerCase() !== 'geometry') && (propiedades.toLowerCase() !== 'id') && (propiedades.toLowerCase() !== 'dir_foto') && (propiedades.toLowerCase() !== 'all_foto')) {
            //console.log(propiedades);
            result = result + ' <p><b>' + propiedades + ': </b>' + objeto[propiedades] + ' </p>';
        }
    }
    return result;
}

map.addInteraction(select);


